.imprimible {
    width: 180px;
    max-width: 180px;
    display: none;
    font-size: 11px;
    font-family: 'Times New Roman';
    color: black !important;
}

.txt-end {
    text-align: end;
}

.foo-ticket {
    margin-top: 6em;
}

td,
th,
tr,
table {
    border-collapse: collapse;
}

td.producto,
th.producto {
    width: 130px;
    max-width: 130px;
}

td.precio,
th.precio {
    width: 50px;
    max-width: 50px;
    word-break: break-all;
}

.centrado {
    text-align: center;
    align-content: center;
}

.t-info>p {
    margin-bottom: 0 !important;
}

#contenedor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#contenedor>div {
    width: 50%;
    height: 17.28px;
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {
    .main-container {
        padding: 0px !important;
    }

    .page-break {
        display: block;
        page-break-before: always;
    }

    .noImprimible {
        display: none !important;
    }

    .imprimible {
        display: block !important;
    }


}