.initial-container-logo {
    padding-top: 13em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.initial-logo {
    width: 32em;
    box-shadow: rgb(0 0 0 / 10%) 0px 25px 20px -20px;
}

.initial-container-powered {
    display: flex;
    justify-content: center;
}

.initial-powered {
    padding-top: 10em;
    color: #002845;
    font-size: large;
}

.initial-img-powered {
    width: 130px;
}