:root {
    --colorPrincipal: #4597bd;
    --colorSecundario: #1c6ba3;
}

.anticon {
    color: var(--colorPrincipal);
}

.ant-btn {
    border-radius: 35px;
}

.ant-modal-header {
    background: var(--colorPrincipal) !important;
    border-radius: 15px 15px 0px 0px;
}

.ant-modal-title {
    color: white !important;
}

.ant-modal-close-x {
    color: white !important;
}

.ant-btn.ant-btn-primary {
    background-color: white;
    border-color: var(--colorPrincipal);
    color: var(--colorPrincipal);
}

.ant-btn.ant-btn-primary:hover {
    background-color: var(--colorPrincipal);
    border-color: var(--colorPrincipal);
    color: white;

}

.ant-btn.ant-btn-default:hover {
    border-color: var(--colorPrincipal);
    color: var(--colorPrincipal);

}

.ant-modal-content {
    border-radius: 15px;
}

.ant-upload.ant-upload-select-picture-card:hover {
    border-color: var(--colorPrincipal);
}

.ant-input:hover {
    border-color: var(--colorPrincipal);
}

.ant-select:hover {
    border-color: var(--colorPrincipal) !important;
}

.ant-select-single:hover {
    border-color: var(--colorPrincipal);
}

.ant-select-selector:hover {
    border-color: var(--colorPrincipal);
}

.ant-switch-checked {
    background-color: var(--colorPrincipal);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--colorPrincipal);
    -webkit-box-shadow: 0 0 0 2px rgb(115 100 72 / 20%);
    box-shadow: 0 0 0 2px rgb(115 100 72 / 20%);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgb(115 100 72 / 20%);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgb(115 100 72 / 20%);
}

.ant-input:focus,
.ant-input-focused {
    border-color: var(--colorPrincipal);
    -webkit-box-shadow: 0 0 0 2px rgb(115 100 72 / 20%);
    box-shadow: 0 0 0 2px rgb(115 100 72 / 20%);
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: white;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--colorPrincipal);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    border-color: var(--colorPrincipal);
    -webkit-box-shadow: 0 0 0 2px rgb(115 100 72 / 20%);
    box-shadow: 0 0 0 2px rgb(115 100 72 / 20%);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--colorPrincipal);
    text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
    color: var(--colorPrincipal);
    background: white;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
    color: var(--colorPrincipal);
}

.ant-pagination-item-active a {
    color: var(--colorPrincipal);
}

.ant-pagination-item-active {
    border-color: var(--colorSecundario);
}

.ant-pagination-item-active:hover a {
    color: var(--colorSecundario);
}

.ant-pagination-item-active:hover {
    border-color: var(--colorPrincipal);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--colorPrincipal);
}

.ant-btn.ant-btn-primary:hover .anticon-loading {
    color: white;
}

.btn-login .anticon-loading {
    color: white;
}

.ant-modal-close-icon {
    color: white;
}

.ant-select-selector {
    border-radius: 10px !important;
}

.ant-input {
    border-radius: 10px;
}

.ant-picker {
    border-radius: 10px;
    width: 100%;
}

.ant-input-affix-wrapper {
    border-radius: 10px;
}

.ant-spin-dot-item {
    background-color: var(--colorPrincipal);
}

.ant-carousel .slick-dots li.slick-active button {
    background: var(--colorSecundario);
}

.ant-carousel .slick-dots li button {
    background-color: var(--colorPrincipal);
}

.ant-carousel .slick-track {
    left: 29%;
    height: 300px !important;
}

.ant-picker:hover,
.ant-picker-focused {
    border-color: var(--colorPrincipal);
}

.ant-table-summary {
    background: white;
}

.ant-drawer-header {
    background-color: var(--colorSecundario);
}

.ant-drawer-title {
    color: white;
}