.app-templates-x-title {
	text-align: center;
	width: 1600px;
	margin: 0;
	margin-bottom: 12px;
}

.app-templates-y-title {
	writing-mode: vertical-rl;
	-webkit-text-orientation: upright;
	text-orientation: upright;
	text-align: center;
	height: 300px;
	margin: 0;
	margin-right: 12px;
}

.app-templates-level {
	display: flex;
	margin: 0;
}

.app-template-room-container {
	width: 100px;
	height: 100px;
	padding: 5px;
}

.app-template-room {
	width: 100%;
	height: 100%;
	background-color: burlywood;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	text-align: center;
	padding-top: 34px;
}

.app-template-room-disabled {
	background-color: #a3a3ad;
}

.app-modal-template button:not(:last-child) {
	margin-bottom: 15px;
}
