code {
	color: var(--colorPrincipal);
}

.color-p {
	color: var(--colorPrincipal);
}

.menu-color {
	background: var(--colorPrincipal);
}

.all-width {
	width: 100% !important;
}

.icon-barr {
	color: white !important;
}

.main-container {
	padding-top: 2rem;
	padding-right: 2rem;
	padding-bottom: 5rem;
	padding-left: 2rem;
	margin-bottom: 2rem;

}

.mrg-2 {
	margin-top: 1.8em;
}

.detail-container {
	padding: 1rem;
	box-shadow: rgb(0 0 0 / 7%) 0px 1px 1px, rgb(0 0 0 / 7%) 0px 2px 2px,
		rgb(0 0 0 / 7%) 0px 4px 4px, rgb(0 0 0 / 7%) 0px 8px 8px,
		rgb(0 0 0 / 7%) 0px 16px 16px;
	border-radius: 20px;
	overflow: auto;
}

.img-layout {
	width: 120px;
	position: fixed;
	bottom: 5px;
	right: 0px;
}

.container-powered {
	text-align: start;
	bottom: 0px;
	/* left: 43%; */
	margin-bottom: 0px;
	color: var(--colorPrincipal);
	margin-left: 1rem;
	position: absolute;
}

.collapse {
	display: none;
}

.collapse.in {
	display: block
}


.img-powered {
	width: 90px;
}

.img-side-bar {
	width: 100%;
	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: -1;
}

.title {
	color: #202022;
}

.hr-title {
	border-top: 1px solid white;
	margin-bottom: 4rem;
	box-shadow: rgb(0 0 0 / 9%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px,
		rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px,
		rgb(0 0 0 / 9%) 0px 32px 16px;
}

.detail-container {
	padding: 1rem;
	box-shadow: rgb(0 0 0 / 7%) 0px 1px 1px, rgb(0 0 0 / 7%) 0px 2px 2px,
		rgb(0 0 0 / 7%) 0px 4px 4px, rgb(0 0 0 / 7%) 0px 8px 8px,
		rgb(0 0 0 / 7%) 0px 16px 16px;
	border-radius: 20px;
	overflow: auto;
}

.img-layout {
	width: 120px;
	position: fixed;
	bottom: 5px;
	right: 0px;
}

.img-side-bar {
	width: 100%;
	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: -1;
}

.title {
	color: #202022;
}

.hr-title {
	border-top: 1px solid white;
	margin-bottom: 4rem;
	box-shadow: rgb(0 0 0 / 9%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px,
		rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px,
		rgb(0 0 0 / 9%) 0px 32px 16px;
}

button[class~='ant-btn-icon-only']>svg {
	margin-top: 5px;
}

.btn-submit {
	background-color: var(--colorPrincipal) !important;
	border-color: var(--colorPrincipal) !important;
	color: white !important;
	border-radius: 10px;
}

.btn-submit:hover {
	background-color: var(--colorSecundario) !important;
	border-color: var(--colorPrincipal) !important;
	color: white !important;
}

.div-right {
	float: right;
}

.content-image-viewer {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	position: relative;
}

.image-viewer {
	margin: 5px;
	cursor: pointer;
	overflow: hidden;
}

.btn-delete-img {
	float: right;
	z-index: 1;
	margin-top: 2px;
	margin-right: 2px;
}

.scrollNav::-webkit-scrollbar {
	display: none;
}

.content-footer {
	background-color: white;
	width: 100%;
	height: 60px;
	box-shadow: rgb(0 0 0 / 20%) 0px 25px 20px 20px;
	margin-top: auto;
	position: relative;
}

.layout {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.hero-text {
	position: relative;
	padding-left: 75px;
	padding-top: 7em;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	line-height: 3em;
}

.hero-text h1 {
	color: #202022;
	font-size: 50px;
	font-weight: 700;
	margin-bottom: 20px;
}

.txt-money {
	text-align: center !important;
	font-weight: bold;
	font-size: large;
}

.txt-money .ant-input {
	text-align: center !important;
}

.hero-text h4 {
	color: var(--colorPrincipal);
}

.img-notfound {
	width: 100%;
}

.hand {
	cursor: pointer;
}

::-webkit-scrollbar {
	width: 5px !important;
}

::-webkit-scrollbar:horizontal {
	height: 3px !important;
}

::-webkit-scrollbar-track {
	background-color: white !important;
}

::-webkit-scrollbar-thumb {
	background-color: var(--colorPrincipal) !important;
}

@media only screen and (max-width: 700px) {
	.main-container {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

@media only screen and (max-width: 500px) {
	.main-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}