.login-row {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 17%;
}

.logo {
	height: 100px;
	display: block;
	margin: auto;
	margin-bottom: 30px;
}

.login-form {
	margin: auto;
	display: block;
	max-width: 400px;
}

.login-circle {
	border-top-left-radius: 20%;
	border-top-right-radius: 10%;
	border-bottom-right-radius: 20%;
	border-bottom-left-radius: 10%;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	background-color: var(--blanco);
}

.login-container {
	padding: 10%;
}
